<template>
  <div :style="rootStyle">
    <div class="viewport" :style="viewportStyle">
      <div ref="spacer" :style="spacerStyle">
        <slot v-bind="visibleItems"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    // Height of each row, give it an initial value but this gets calculated dynamically on mounted
    rowHeight: {
      type: Number,
      default() {
        return 30;
      }
    },
    // Total height of the root which contains all the list items in px

    rootHeight: {
      type: Number,
      default() {
        return 300;
      }
    }
  },
  data() {
    return {
      // Current scroll top position, we update this inside the scroll event handler
      scrollTop: 0,
      animationFrame: null
      // Extra padding at the top and bottom so that the items transition smoothly
    };
  },
  mounted() {
    this.$el.addEventListener(
      "scroll",
      this.handleScroll,
      this.doesBrowserSupportPassiveScroll() ? { passive: true } : false
    );
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        // Calculate that initial row height dynamically
        if (this.largestHeight && this.$refs.spacer) {
          const largestHeight = this.calculateInitialRowHeight();
          this.rowHeight =
            typeof largestHeight !== "undefined" && largestHeight !== null ? largestHeight : 50;
        }
      }
    }
  },
  destroyed() {
    this.$el.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    // Think of it as extra items just before the viewport starts and just after the viewport ends
    nodePadding() {
      return this.rowHeight - 10;
    },
    viewportHeight() {
      return this.itemCount * this.rowHeight;
    },
    startIndex() {
      let startNode = Math.floor(this.scrollTop / this.rowHeight) - this.nodePadding;
      startNode = Math.max(0, startNode);
      return startNode;
    },
    visibleNodeCount() {
      let count = Math.ceil(this.rootHeight / this.rowHeight) + 2 * this.nodePadding;
      count = Math.min(this.itemCount - this.startIndex, count);
      return count;
    },
    visibleItems() {
      return this.items.slice(this.startIndex, this.startIndex + this.visibleNodeCount);
    },
    itemCount() {
      return this.items.length;
    },
    offsetY() {
      return this.startIndex * this.rowHeight;
    },
    spacerStyle() {
      return {
        transform: "translateY(" + this.offsetY + "px)"
      };
    },
    viewportStyle() {
      return {
        overflow: "visible",
        height: this.viewportHeight + "px",
        position: "relative"
      };
    },
    rootStyle() {
      return {
        height: this.rootHeight + "px",
        overflow: "auto",
        overscrollBehavior: "contain"
      };
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop = this.$el.scrollTop;
    },
    /**
    Find the largest height amongst all the children
    Remember each row has to be of the same height
    I am working on the different height version
    */
    calculateInitialRowHeight() {
      const children = this.$refs.spacer.children;
      let largestHeight = 0;
      for (let i = 0; i < children.length; i++) {
        if (children[i].offsetHeight > largestHeight) {
          largestHeight = children[i].offsetHeight;
        }
      }
      return largestHeight;
    },
    doesBrowserSupportPassiveScroll() {
      let passiveSupported = false;

      try {
        const options = {
          get passive() {
            // This function will be called when the browser
            //   attempts to access the passive property.
            passiveSupported = true;
            return false;
          }
        };

        window.addEventListener("test", null, options);
        window.removeEventListener("test", null, options);
      } catch (err) {
        passiveSupported = false;
      }
      return passiveSupported;
    }
  }
};
</script>

<style lang="scss" scoped>
.viewport {
  background: #fefefe;
  overflow-y: auto;
}

.spacer > div {
  padding: 0.5rem 0rem;
  border: 1px solid #f5f5f5;
}
</style>
