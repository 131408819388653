<template>
  <container>
    <div class="p-2">
      <DxDataGrid
        title="Role Management"
        gridName="roles"
        @initialized="initialize"
        :columns="columns"
        :dataSource="roles"
        :toolbar="toolbar"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        columnResizingMode="widget"
        :showBorders="true"
        :allow-column-reordering="true"
        :searchPanel="searchPanel"
        :cacheEnabled="false"
      >
        <template v-slot:pageTitle>
          <h2>Role Management</h2>
        </template>
        <template v-slot:extraActions>
          <add-button type="button" @click="handleAddRole" />
        </template>
        <template v-slot:actions="{ data }">
          <div class="d-flex justify-content-center">
            <icon-button
              class="text-primary pointer mr-2 p-0"
              v-tooltip.right="'Edit role.'"
              @click.stop="editRole(data)"
              icon="pen-alt"
            />
            <icon-button
              v-if="permissions.RoleDelete"
              v-tooltip.right="'Delete role.'"
              @click.stop="removeRole(data)"
              class="text-danger pointer ml-2 p-0"
              icon="trash-alt"
            />
          </div>
        </template>
        <template v-slot:dateCell="{ data }">
          <div class="d-flex align-items-center">
            <icon icon="circle" class="filled_circle" :class="dueDateCell(data)" />
            <b>{{ data.text }}</b>
          </div>
        </template>
      </DxDataGrid>
      <RoleManagementForm
        v-if="isAdding || isEditing"
        :selectedRole="selectedRole"
        :labId="currentLab"
        @cancel="cancel"
        @submit="handleSubmit"
      />
    </div>
  </container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import RolesAPI from "@/services/roles";
import RoleManagementForm from "@/components/forms/CodeMaintenance/RoleManagement.vue";
import auditLog from "../../services/AuditLog";
import { createLogItem } from "../../modules/helpers";
import { DropdownApi } from "@/services";
import DataSource from "devextreme/data/data_source";
import Container from "../common/Container.vue";
import IconButton from "../common/IconButton.vue";
import Icon from "../common/Icon.vue";
import { DxDataGrid } from "devextreme-vue/data-grid";
import AddButton from "../common/AddButton.vue";

export default {
  name: "Role-Management",
  metaInfo: {
    title: "Role Management",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    RoleManagementForm,
    DxDataGrid,
    Container,
    IconButton,
    Icon,
    AddButton
  },
  provide() {
    return {
      grid: () => this.grid
    };
  },
  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited admin roles page."
    });
  },
  data() {
    return {
      toolbar: {
        items: [
          { location: "after", template: "extraActions" },
          { template: "pageTitle", location: "before" },
          { name: "searchPanel" }
        ]
      },
      roles: new DataSource({ store: DropdownApi.searchRoles, pageSize: 10, paginate: true }),
      grid: {},
      availableLabs: [],
      columns: [
        {
          dataField: "displayName",
          caption: "Name",
          width: "40%"
        },
        {
          dataField: "description",
          width: "50%"
        },
        {
          caption: "Actions",
          cellTemplate: "actions",
          width: "10%",
          class: "text-center"
        }
      ],
      isEditing: false,
      isAdding: false,
      selectedRole: null,
      searchPanel: { visible: true, width: 300 }
    };
  },

  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapGetters(["permissions"])
  },
  methods: {
    initialize({ component }) {
      this.grid = component;
    },
    cancel() {
      this.isEditing = false;
      this.isAdding = false;
      this.selectedRole = null;
    },
    handleSubmit() {
      this.isEditing = false;
      this.isAdding = false;
      this.selectedRoleId = null;
      this.roles.reload();
    },
    async editRole({ data }) {
      if (this.isEditing || this.isAdding) {
        const confirm = await window.confirm(
          "You may have unsaved data. \n Are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.selectedRole = { ...data };
      this.isEditing = true;
    },
    async removeRole({ data }) {
      if (data) {
        const confirm = await window.confirm(
          `WARNING: This action is irreversible. Are you sure you wish to continue?`
        );
        if (confirm) {
          return RolesAPI.deleteRole(data)
            .then(() => this.grid.refresh(true))
            .catch(() => {
              window.alert("Error: Cannot delete role with linked users.");
            });
        }
      }
    },
    async handleAddRole() {
      if (this.isEditing) {
        const confirm = await window.confirm(
          "WARNING: You may have unsaved changes,\n Would you like to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.selectedRole = null;
      this.isAdding = true;
      this.isEditing = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
