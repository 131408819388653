<template>
  <form
    @submit.prevent="handleSubmit"
    action=""
    class="w-75 pt-3 m-auto"
    v-shortkey="saveShortkey"
    @shortkey="handleSubmit"
  >
    <div class="my-3 row">
      <h4 class="col" v-if="selectedRole">Edit Lab Role</h4>
      <h4 class="col" v-else>Add Lab Role</h4>
    </div>
    <div class="row">
      <text-input
        label="Name"
        name="name"
        class="col"
        maxLength="51"
        v-model="role.name"
        :validator="$v.role.name"
      />
      <select-input
        :items="userTypes"
        :disabled="isEditing"
        v-model="role.userTypeId"
        label="User Type"
        class="col"
        name="userType"
        :validator="$v.role.userTypeId"
      />
      <select-input
        v-if="cytologyModuleEnabled"
        :items="cytReviewTypes"
        v-model="role.cytReviewTypeId"
        label="Cytology Review Type"
        class="col"
        name="cytReviewTypeId"
      />
      <select-input
        :items="[
          { displayName: 'Yes', id: true },
          { displayName: 'No', id: false }
        ]"
        label="Is Pathologist"
        id="doctor"
        class=""
        name="doctor"
        v-model="role.doctor"
      />
    </div>
    <div class="row"></div>
    <div class="row my-4">
      <text-input
        class="col"
        name="description"
        label="Description"
        maxLength="101"
        v-model="role.description"
        :validator="$v.role.description"
      />
    </div>
    <div v-if="isEditing" class="my-4">
      <div role="tabpanel" class="d-flex tabs">
        <span
          role="tab"
          v-for="item in ['permissions', 'reports', 'tags', 'procedures', 'panels', 'holdcodes']"
          :key="item"
          @click="currentForm = item"
          class="text-capitalize tab-item pointer font-weight-bold rounded"
          :class="{ active: currentForm === item }"
        >
          {{ item === "procedures" ? "orders" : item }}
        </span>
      </div>
      <div class="permissions_filter d-flex justify-content-between">
        <div class="permissions_available d-flex flex-column">
          <h6 class="text-capitalize">Available {{ currentForm }}</h6>
          <input
            :disabled="!available[currentForm].length"
            class="mb-2 w-full form-control"
            :noLabel="true"
            placeholder="Filter"
            type="search"
            v-stream:input="availableSearch$"
          />
        </div>
        <div class="permissions_assigned">
          <h6 class="text-capitalize">Assigned {{ currentForm }}</h6>
          <input
            :disabled="!assigned[currentForm].length"
            class="w-full mb-2 form-control"
            :noLabel="true"
            type="search"
            placeholder="Filter"
            v-stream:input="assignedSearch$"
          />
        </div>
      </div>
      <div class="permissions_display d-flex justify-content-between">
        <div class="d-flex flex-column">
          <virtual-list
            :rowHeight="40"
            :rootHeight="400"
            :items="availableItems"
            class="permissions_box"
          >
            <template v-slot="items">
              <button
                :class="{
                  selected: selected.available.includes(id)
                }"
                @click="addSelected('available', id)"
                class="text-left list-group-item permission"
                v-for="(id, i) in items"
                :key="i"
                type="button"
              >
                <span class="">
                  {{ target[id].displayName }}
                </span>
              </button>
            </template>
          </virtual-list>
        </div>
        <div class="permissions_actions">
          <button
            :disabled="!selected.available.length"
            type="button"
            @click="addItems(selected.available)"
            class="btn btn-outline-secondary mt-2"
          >
            &rarr; Add
          </button>
          <button
            type="button"
            @click="addItems(newAvailable[currentForm])"
            class="btn btn-outline-secondary my-2"
          >
            &rarr; &rarr; Add all
          </button>

          <button
            @click="removeItems(selected.assigned)"
            type="button"
            :disabled="!selected.assigned.length"
            class="btn btn-outline-secondary"
          >
            &larr; Remove
          </button>
          <button
            @click="removeItems(newAssigned[currentForm])"
            type="button"
            class="btn btn-outline-secondary my-2"
          >
            &larr; &larr; Remove all
          </button>
        </div>
        <div class="d-flex flex-column">
          <virtual-list
            :rowHeight="40"
            :rootHeight="400"
            :items="assignedItems"
            class="permissions_box"
          >
            <template v-slot="items">
              <button
                :class="{
                  selected: selected.assigned.includes(id)
                }"
                @click="addSelected('assigned', id)"
                class="text-left btn d-flex justify-content-between list-group-item permission"
                v-for="id in items"
                :key="id"
                type="button"
              >
                <span>
                  {{ target[id].displayName }}
                </span>
              </button>
            </template>
          </virtual-list>
        </div>
        <div></div>
      </div>
      <div v-if="role.userTypeId > 20">
        <fieldset class="mt-3">
          <legend>Default User Settings</legend>
          <UserSettingsFields
            class="mx-2"
            :value="defaultUserSettings"
            @updateSettings="updateDefaultUserSettings"
          />
        </fieldset>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button @click="cancelEdit" type="button" class="btn btn-danger">Cancel</button>
      <button :disabled="$v.role.$invalid" type="submit" class="btn btn-primary mx-2">
        {{ isEditing ? "Save" : "Next" }}
      </button>
    </div>
  </form>
</template>

<script>
import RolesAPI from "@/services/roles";
import { required, maxLength } from "vuelidate/lib/validators";
import { CytReviewTypeEnum, enumToDropDown, userTypes } from "../../../modules/enums";
import { mapState } from "vuex";
import {
  altKey,
  booleanLookup,
  createLogComment,
  createLogItem,
  escapeRegExp
} from "../../../modules/helpers";
import auditLog from "../../../services/AuditLog";
import { cloneDeep } from "lodash";
import { debounceTime, map, distinctUntilChanged } from "rxjs/operators";
import TextInput from "@/components/common/TextInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import VirtualList from "@/components/common/VirtualList.vue";
import UserSettingsFields from "@/components/UserSettingsFields.vue";
import { defaultUserSettings } from "@/modules/defaultUserSettings";

export default {
  components: { TextInput, SelectInput, VirtualList, UserSettingsFields },
  name: "Role-Form",
  props: {
    selectedRole: {
      default() {
        return null;
      }
    },
    labId: {
      default() {
        return null;
      }
    }
  },
  inject: ["grid"],
  mounted() {
    if (this.selectedRole) {
      this.role = {
        ...this.selectedRole,
        name: this.selectedRole.displayName,
        doctor: this.selectedRole.isDoctor
      };
      this.isEditing = true;
      this.originalRole = cloneDeep({
        ...this.selectedRole,
        name: this.selectedRole.displayName,
        doctor: this.selectedRole.isDoctor
      });
      this.loadPermissions(this.selectedRole.id);
      if (this.selectedRole?.defaultUserSettings) {
        this.defaultUserSettings = JSON.parse(this.selectedRole.defaultUserSettings);
      }
    }
    if (this.currentLab !== null) {
      this.userTypes = userTypes.filter(e => e.id !== 40);
    }
  },

  data() {
    return {
      availableFilter: "",
      assignedFilter: "",
      isEditing: false,
      currentForm: "permissions",
      cytReviewTypes: enumToDropDown(CytReviewTypeEnum),
      available: {
        sort: 1,
        permissions: [],
        reports: [],
        procedures: [],
        panels: [],
        tags: [],
        holdcodes: [],
        macros: []
      },
      assigned: {
        sort: 1,
        permissions: [],
        reports: [],
        procedures: [],
        panels: [],
        tags: [],
        holdcodes: [],
        macros: []
      },
      newAssigned: {
        permission: [],
        reports: [],
        procedures: [],
        panels: [],
        tags: [],
        holdcodes: [],
        macros: []
      },
      newAvailable: {
        permission: [],
        reports: [],
        procedures: [],
        panels: [],
        tags: [],
        holdcodes: [],
        macros: []
      },
      permissions: {},
      tags: {},
      reports: {},
      originalRole: {},
      procedures: {},
      panels: {},
      holdcodes: {},
      selected: { available: [], assigned: [] },
      role: {
        name: "",
        description: "",
        doctor: false,
        admin: false,
        cytReviewTypeId: 10
      },
      userTypes: [...userTypes],
      saveShortkey: altKey("s"),
      booleanOptions: booleanLookup.dataSource,
      defaultUserSettings: defaultUserSettings
    };
  },
  validations: {
    role: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      description: {
        maxLength: maxLength(100)
      },
      userTypeId: {
        required
      }
    }
  },
  domStreams: ["assignedSearch$", "availableSearch$"],
  subscriptions() {
    const searchAssignedInput$ = this.assignedSearch$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(({ event }) => event.target.value)
    );
    const searchAvailableInput$ = this.availableSearch$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(({ event }) => event.target.value)
    );
    return {
      searchAvailableInput$,
      searchAssignedInput$
    };
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      cytologyModuleEnabled: state => state.labSettings.CytologyModuleEnabled
    }),
    availableItems() {
      let list = [];
      if (this.searchAvailableInput$) {
        list = this.newAvailable[this.currentForm].filter(id => {
          const matcher = new RegExp(escapeRegExp(this.searchAvailableInput$), "i");
          return matcher.test(this.target[id]?.displayName);
        });
      } else {
        list = this.newAvailable[this.currentForm];
      }
      return this.sortItems(list, true);
    },
    assignedItems() {
      let list = [];
      if (this.searchAssignedInput$) {
        list = this.newAssigned[this.currentForm].filter(id => {
          const matcher = new RegExp(escapeRegExp(this.searchAssignedInput$), "i");
          return matcher.test(this.target[id]?.displayName);
        });
      } else {
        list = this.newAssigned[this.currentForm];
      }
      return this.sortItems(list, true);
    },
    target() {
      return this[this.currentForm];
    }
  },
  watch: {
    selectedRole(nv, ov) {
      if (nv === null) {
        this.isEditing = false;
        this.resetData();
      } else if (nv.id && nv.id !== ov?.id) {
        this.role = {
          ...this.selectedRole,
          name: this.selectedRole.displayName,
          doctor: this.selectedRole.isDoctor
        };
        this.isEditing = true;
        if (this.selectedRole?.defaultUserSettings) {
          this.defaultUserSettings = JSON.parse(this.selectedRole.defaultUserSettings);
        }
        return this.loadPermissions(nv.id);
      }
    },
    currentForm(nv, ov) {
      if (nv !== ov) {
        this.selected.available = [];
        this.selected.assigned = [];
      }
    }
  },
  methods: {
    sortItems(items, sort) {
      if (!items) {
        return items;
      }
      return items.sort((a, b) => {
        if (sort) {
          return this.target[a].displayName.toLowerCase() < this.target[b].displayName.toLowerCase()
            ? -1
            : 1;
        } else {
          return this.target[a].displayName.toLowerCase() < this.target[b].displayName.toLowerCase()
            ? 1
            : -1;
        }
      });
    },
    async loadPermissions(roleId) {
      const handleResponse = response => {
        const normalizeAssigned = new (this.normalizeData(response.assignedPermissions || []))();
        const normalizeAvailable = new (this.normalizeData(response.availablePermissions || []))();
        const normalizeAssignedReports = new (this.normalizeData(
          response.assignedReports.filter(e => e.labId === this.currentLab) || []
        ))();
        const normalizeAvailableReports = new (this.normalizeData(
          response.availableReports.filter(e => e.labId === this.currentLab) || []
        ))();
        const normalizeAssignedProcedures = new (this.normalizeData(
          response.assignedProcedures || []
        ))();
        const normalizeAvailableProcedures = new (this.normalizeData(
          response.availableProcedures || []
        ))();

        const normalizeAssignedPanels = new (this.normalizeData(response.assignedPanels || []))();
        const normalizeAvailablePanels = new (this.normalizeData(response.availablePanels || []))();
        const normalizeAssignedTags = new (this.normalizeData(response.assignedTags || []))();
        const normalizeAvailableTags = new (this.normalizeData(response.availableTags || []))();
        const normalizeAssignedHoldCodes = new (this.normalizeData(
          response.assignedHoldCodes || []
        ))();
        const normalizeAvailableHoldCodes = new (this.normalizeData(
          response.availableHoldCodes || []
        ))();
        const normalizeAssignedMacros = new (this.normalizeData(response.assignedMacros || []))();
        const normalizeAvailableMacros = new (this.normalizeData(response.availableMacros || []))();
        this.permissions = {
          ...normalizeAvailable.entities,
          ...normalizeAssigned.entities
        };
        this.reports = {
          ...normalizeAssignedReports.entities,
          ...normalizeAvailableReports.entities
        };
        this.procedures = {
          ...normalizeAssignedProcedures.entities,
          ...normalizeAvailableProcedures.entities
        };
        this.panels = {
          ...normalizeAssignedPanels.entities,
          ...normalizeAvailablePanels.entities
        };
        this.tags = {
          ...normalizeAssignedTags.entities,
          ...normalizeAvailableTags.entities
        };
        this.holdcodes = {
          ...normalizeAssignedHoldCodes.entities,
          ...normalizeAvailableHoldCodes.entities
        };
        this.macros = {
          ...normalizeAssignedMacros.entities,
          ...normalizeAvailableMacros.entities
        };
        const assigned = {};
        const available = {};
        const newAvailable = {};
        const newAssigned = {};
        // ==================
        assigned.permissions = Array.from([...normalizeAssigned.ids]);
        assigned.procedures = Array.from([...normalizeAssignedProcedures.ids]);
        assigned.reports = Array.from([...normalizeAssignedReports.ids]);
        assigned.panels = Array.from([...normalizeAssignedPanels.ids]);
        assigned.tags = Array.from([...normalizeAssignedTags.ids]);
        assigned.holdcodes = Array.from([...normalizeAssignedHoldCodes.ids]);
        assigned.macros = Array.from([...normalizeAssignedMacros.ids]);
        // ====================
        available.permissions = Array.from([...normalizeAvailable.ids]);
        available.reports = Array.from([...normalizeAvailableReports.ids]);
        available.procedures = Array.from([...normalizeAvailableProcedures.ids]);
        available.panels = Array.from([...normalizeAvailablePanels.ids]);
        available.tags = Array.from([...normalizeAvailableTags.ids]);
        available.holdcodes = Array.from([...normalizeAvailableHoldCodes.ids]);
        available.macros = Array.from([...normalizeAvailableMacros.ids]);
        // =====================
        newAssigned.permissions = Array.from([...normalizeAssigned.ids]);
        newAssigned.procedures = Array.from([...normalizeAssignedProcedures.ids]);
        newAssigned.reports = Array.from([...normalizeAssignedReports.ids]);
        newAssigned.panels = Array.from([...normalizeAssignedPanels.ids]);
        newAssigned.tags = Array.from([...normalizeAssignedTags.ids]);
        newAssigned.holdcodes = Array.from([...normalizeAssignedHoldCodes.ids]);
        newAssigned.macros = Array.from([...normalizeAssignedMacros.ids]);
        // =====================
        newAvailable.permissions = Array.from([...normalizeAvailable.ids]);
        newAvailable.reports = Array.from([...normalizeAvailableReports.ids]);
        newAvailable.procedures = Array.from([...normalizeAvailableProcedures.ids]);
        newAvailable.panels = Array.from([...normalizeAvailablePanels.ids]);
        newAvailable.tags = Array.from([...normalizeAvailableTags.ids]);
        newAvailable.holdcodes = Array.from([...normalizeAvailableHoldCodes.ids]);
        newAvailable.macros = Array.from([...normalizeAvailableMacros.ids]);

        this.newAvailable = newAvailable;
        this.newAssigned = newAssigned;
        this.assigned = assigned;
        this.available = available;
      };
      if (roleId) {
        //Get report groups by role
        return RolesAPI.getRolePermissions(roleId).then(handleResponse);
      } else {
        return RolesAPI.getAllPermissions().then(handleResponse);
      }
    },
    async cancelEdit() {
      const confirm = await window.confirm(
        "You may have unsaved data. \n Are you sure you want to cancel?"
      );
      if (confirm) {
        this.resetData();
        this.$emit("cancel");
      }
    },
    changePage(direction, property, max) {
      if (direction === "increase") {
        if (this[property].pageOffset + 1 < max) {
          this[property].pageOffset++;
        }
      } else {
        if (this[property].pageOffset - 1 >= 0) {
          this[property].pageOffset--;
        }
      }
    },
    normalizeData(data) {
      function normalized() {
        this.entities = {};
        this.ids = [];
        for (const entity of data) {
          this.entities[entity.id] = entity;
          this.ids.push(entity.id);
        }
      }
      return normalized;
    },
    resetData() {
      this.selected = { available: [], assigned: [] };
      this.assigned = { current: [], new: [], sort: 1 };
      this.available = { current: [], new: [], sort: 1 };
      this.permissions = {};
      this.role = {
        name: "",
        description: "",
        admin: false,
        doctor: false
      };
      this.assignedFilter = "";
      this.availableFilter = "";
      this.defaultUserSettings = {
        pathReportLocation: null,
        expandDemo: false,
        autoOpenCase: false,
        automaticMacroPopup: null,
        macroSearchMode: null,
        macroStartsWith: null,
        enableSpellchecker: true,
        macroAssist: false,
        imagePrintOnReport: false,
        defaultImageType: false,
        segregateResultsMacros: false,
        defaultDashboardMode: 0,
        autoOpenEditors: false,
        confirmRemoveHold: true,
        nextCasePopup: 0,
        autoFillNextCase: 0,
        defaultPrintMode: null
      };
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (!this.isEditing) {
        const role = await RolesAPI.addRole({
          ...this.role,
          labId: this.currentLab,
          permissions: [],
          reportGroups: [],
          macros: [],
          holdcodes: [],
          procedures: [],
          tags: [],
          reports: [],
          defaultUserSettings: JSON.stringify(this.defaultUserSettings)
        });
        this.role.id = role.id;
        this.isEditing = true;
        this.loadPermissions(role.id);
        if (this.grid) {
          this.grid().refresh(true);
        }
        const logItem = createLogItem({}, 4);
        logItem.comments = `Created a new role ${this.role.name}`;
        auditLog.insertLogMessage(logItem);
        return;
      }

      const sets = {
        permissions: new Set(),
        reports: new Set(),
        panels: new Set(),
        tags: new Set(),
        procedures: new Set(),
        holdcodes: new Set(),
        macros: new Set()
      };

      for (const property in this.newAssigned) {
        this.newAssigned[property].forEach(item => {
          sets[property].add(item);
        });
        this.role[property] = [...sets[property]].map(e => this[property][e]);
        this.originalRole[property] = this.assigned[property].map(e => this[property][e]);
      }
      const hasChanges = JSON.stringify(this.originalRole) !== JSON.stringify(this.role);
      this.role.reportGroups = this.role.reports;
      if (hasChanges && this.originalRole.id === this.role.id) {
        const logItem = createLogItem({}, 5);
        logItem.comments = `${this.role.name}:${createLogComment(this.originalRole, this.role)}`;
        auditLog.insertLogMessage(logItem);
      }
      const updatedRole = await RolesAPI.updateRole({
        ...this.role,
        labId: this.currentLab,
        defaultUserSettings: JSON.stringify(this.defaultUserSettings)
      });
      if (updatedRole.validationErrors?.length) {
        window.alert(updatedRole.validationErrors.join(", "));
        return;
      }

      this.$emit("submit");
      this.resetData();
    },
    addItems(selecteditems) {
      let availableitems = Array.from(this.newAvailable[this.currentForm]);
      for (const id of selecteditems) {
        if (this.assigned[this.currentForm].includes(id)) {
          const item = this.target[id];
          item.isDeleted = false;
        }
        if (this.newAvailable[this.currentForm].includes(id)) {
          availableitems = availableitems.filter(e => e != id);
        }
        this.newAssigned[this.currentForm].push(id);
      }
      this.newAvailable[this.currentForm] = [...availableitems];
      this.selected.available = [];
    },
    removeItems(selecteditems) {
      let assigneditems = [...this.newAssigned[this.currentForm]];

      for (const itemId of selecteditems) {
        if (this.assigned[this.currentForm].includes(itemId)) {
          const item = this.target[itemId];
          item.isDeleted = true;
        }
        if (this.newAssigned[this.currentForm].includes(itemId)) {
          assigneditems = assigneditems.filter(e => e != itemId);
        }
        this.newAvailable[this.currentForm].push(itemId);
      }
      this.newAssigned[this.currentForm] = [...assigneditems];
      this.selected.assigned = [];
    },
    addSelected(property, item) {
      if (!this.selected[property].includes(item)) {
        this.selected[property].push(item);
      } else {
        const index = this.selected[property].indexOf(item);
        this.selected[property].splice(index, 1);
      }
    },
    updateDefaultUserSettings(value) {
      this.defaultUserSettings = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  margin: 0 0.2rem;
  padding: 0.5rem 0.25rem;
  &.active {
    border-bottom: none;
    background: gray;
    color: white;
  }
}

.permissions_box {
  width: 300px;
  padding: 0.25rem 0.25rem 0.75rem 0.25rem;
  border: 1px solid $gray;
}
.permissions_actions {
  display: flex;
  flex-direction: column;
  button {
    width: 140px;
  }
}
.permissions_available,
.permissions_assigned {
  width: 300px;
}

.selected {
  background-color: $primary;
  color: white;
}
.pagination_box {
  justify-content: center;
  display: flex;
  align-self: center;
  align-items: center;
  font-size: 1.3rem;
  button {
    font-size: 1.3rem;
    color: $primary;
    margin: 0 0.75rem;
  }
}
.permission {
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 0.05rem 0;
  width: 100%;
  /* color: $green; */
  & > span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    &:hover {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }
  &:focus {
    outline: none;
  }
  &:disabled,
  &.removed {
    color: hsl(208, 7%, 80%) !important;
  }
}
</style>
