import { render, staticRenderFns } from "./VirtualList.vue?vue&type=template&id=1c18ddb7&scoped=true&"
import script from "./VirtualList.vue?vue&type=script&lang=js&"
export * from "./VirtualList.vue?vue&type=script&lang=js&"
import style0 from "./VirtualList.vue?vue&type=style&index=0&id=1c18ddb7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c18ddb7",
  null
  
)

export default component.exports